const formItems = [
    {
        title: '文本',
        config: {
            component: 'input',
            type: 'text',
            label: '',
            placeholder: '请输入'
        }
    },
    {
        title: '数字',
        config: {
            component: 'input',
            type: 'number',
            label: '',
            placeholder: '请输入'
        }
    },
    {
        title: '多行文本',
        config: {
            component: 'input',
            type: 'textarea',
            label: '',
            placeholder: '请输入'
        }
    },
    {
        title: '下拉选择',
        config: {
            component: 'select',
            mode: 'selector',
            label: '',
            placeholder: '请选择',
            options: ['选项一', '选项二'],
        }
    },
    // {
    //     title: '单选',
    //     config: {
    //         component: 'radio',
    //         label: '',
    //         options: ['选项一', '选项二']
    //     }
    // },
    {
        title: '日期',
        config: {
            component: 'select',
            mode: 'date',
            label: '日期',
            placeholder: '请选择日期'
        }
    },
    {
        title: '时间',
        config: {
            component: 'select',
            mode: 'time',
            label: '时间',
            placeholder: '请选择时间'
        }
    },
    {
        title: '身份证号',
        config: {
            component: 'input',
            type: 'idcard',
            label: '身份证号',
            placeholder: '请输入身份证号码',
        }
    },
    {
        title: '图片上传',
        config: {
            component: 'upload',
            label: '',
            limit: 9,
        }
    },
    // {
    //     title: '视频上传',
    //     config: {
    //         component: 'upload',
    //         label: '',
    //         count: 1,
    //         type: 2
    //     }
    // }
]

//基本信息表单（每个表单必有且不可更改）
const base = [
    {
        component: 'input',
        key: 'name',
        type: 'text',
        label: '姓名',
        must: true,
        fixed: true,
        placeholder: '请输入您的姓名',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请输入您的姓名'}
            },
        ]
    },
    {
        component: 'input',
        key: 'age',
        type: 'number',
        label: '年龄',
        must: true,
        fixed: true,
        placeholder: '请输入您的年龄',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请输入您的年龄'}
            },
        ]
    },
    {
        component: 'input',
        key: 'mobile',
        label: '手机号码',
        must: true,
        fixed: true,
        placeholder: '请输入您的手机号码',
        rules: [
            {
                type: 1,
                title: '必填',
                rule: {required: true, message: '请输入您的手机号码'}
            },
            {
                type: 2,
                title: '手机号校验',
                rule: {regex: '^1[3-9]\\d{9}$', message: '请输入正确的手机号码'}
            }
        ]
    }
]

const rules = [
    {
        type: 1,
        title: '必填',
        rule: {required: true, message: null}
    },
    {
        type: 2,
        title: '手机号格式校验',
        rule: {regex: '^1[3-9]\\d{9}$', message: '请输入正确的手机号码'}
    },
    {
        type: 3,
        title: '输入字数限制',
        rule: {min: 0, max: 100, message: null}
    },
    {
        type: 4,
        title: '数字大小限制',
        rule: {small: 0, big: 100, message: null}
    },
    {
        type: 5,
        title: '邮箱格式校验',
        rule: {regex: '^[A-Za-z0-9]+([_\\.][A-Za-z0-9]+)*@([A-Za-z0-9\\-]+\\.)+[A-Za-z]{2,6}$', message: '请输入正确的邮箱'}
    },
    {
        type: 6,
        title: '自定义正则校验',
        rule: {regex: null, message: null}
    }
]



export {formItems, rules, base}
