<template>
  <div class="content md flex-box vertical" id="process_detail">
    <a-tabs class="flex-grow" :default-active-key="1" v-model="tab">
      <a-tab-pane :key="1" tab="基础信息">
        <a-form-model
          class="process-form"
          ref="processForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          v-show="edit"
        >
          <a-form-model-item label="流程名称" prop="type">
            <a-select
              v-model="form.type"
              :options="processTypes"
              placeholder="请选择流程"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item
            label="选择群团"
            prop="group_id"
            v-if="form.type == 1"
          >
            <a-select
              v-model="form.group_id"
              :options="groupList"
              placeholder="请选择群团"
            ></a-select>
          </a-form-model-item>
          <a-form-model-item
            label="申请方式"
            prop="is_personal"
            v-if="form.type == 1"
          >
            <a-select
              v-model="form.is_personal"
              :options="personalList"
              placeholder="请选择申请方式"
            ></a-select>
          </a-form-model-item>
        </a-form-model>
        <a-descriptions
          class="process-form"
          title=""
          :column="1"
          v-show="!edit"
          v-if="info"
        >
          <a-descriptions-item label="流程名称">{{
            info.name
          }}</a-descriptions-item>
          <a-descriptions-item label="群团" v-if="info.group">{{
            info.group.name
          }}</a-descriptions-item>
          <a-descriptions-item label="申请方式" v-if="info">{{
            info.is_personal==0?'团体社员':'个人社员'
          }}</a-descriptions-item>
        </a-descriptions>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="表单配置" v-if="showFormConf">
        <custom-form
          class="form-edit"
          ref="formEdit"
          hide-btn
          :list="formControls"
          :style="{ height: height + 'px' }"
          :edit.sync="edit"
          @save="handleFormConfSave"
        ></custom-form>
      </a-tab-pane>
      <a-tab-pane :key="3" tab="审批配置">
        <div class="flex-box person-select">
          <div class="person-select-title">审批人</div>
          <div class="person-select-ctx">
            <div
              class="select-list"
              v-if="selectItems && selectItems.length > 0"
            >
              <div
                class="select-item"
                v-for="(item, i) in selectItems"
                :key="i"
              >
                {{ item.name }}
              </div>
            </div>
            <a-button @click="showModal" class="select-btn" v-show="edit"
              ><a-icon type="select" />选择审批人</a-button
            >
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    <a-space class="footer" v-if="isAdd">
      <a-button @click="prevStep" v-if="tab > 1">上一步</a-button>
      <a-button @click="nextStep" v-if="tab < 3">下一步</a-button>
      <a-button type="primary" @click="handleConfirm" v-if="tab == 3"
        >保存</a-button
      >
    </a-space>
    <a-space class="footer" v-else>
      <template v-if="edit">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleConfirm">保存</a-button>
      </template>
      <a-button type="primary" @click="editProcess" v-else>编辑</a-button>
    </a-space>
    <a-modal
      title="选择审批人"
      :maskClosable="false"
      :closable="false"
      :width="600"
      @ok="confirmSelect"
      v-model="visible"
    >
      <address-book
        class="org-tree"
        ref="orgTree"
        :checked-keys="selectKeys"
        :selectable="false"
        checkable
      ></address-book>
    </a-modal>
  </div>
</template>

<script>
import customForm from "../../components/custom-form";
import addressBook from "../../components/address-book";
import {
  processTypes,
  defaultFormConfig,
  getProcessTypeText,
  getCheckedItems,
} from "../../common/constant/process";
import { clone } from "../../common/js/tool";

export default {
  name: "ProcessAdd",
  components: {
    customForm,
    addressBook,
  },
  data() {
    return {
      tab: 1,
      edit: false,
      info: null,
      form: {},
      showFormConf: true,
      selectKeys: null,
      selectItems: [],
      visible: false,
      processTypes,
      groupList: [],
      personalList: [
        { key: 0, title: "团体社员" },
        { key: 1, title: "个人社员" },
      ],
      formControls: [],
      rules: {
        type: [{ required: true, message: "请选择流程", trigger: "blur" }],
        group_id: [{ required: true, message: "请选择群团", trigger: "blur" }],
        is_personal: [
          { required: true, message: "请选择申请方式", trigger: "blur" },
        ],
      },
      height: null,
      orgList: [],
      isAdd: this.$route.name == "ProcessAdd",
    };
  },
  watch: {
    "form.type"(val) {
      if (val == 1 && this.groupList.length == 0) {
        this.getGroupList();
      }
      if (val && !this.form.form_config) {
        let conf = defaultFormConfig[`f_${val}`];
        if (conf) {
          this.formControls = conf;
          this.$set(this.form, "form_config", JSON.stringify(conf));
        }
      }
    },
  },
  created() {
    this.getOrgList();
  },
  mounted() {
    // 计算内容区域高度
    this.height =
      document.querySelector("#process_detail").clientHeight - 60 - 32 - 65;
  },
  methods: {
    getOrgList() {
      this.$axios("/admin/get-list")
        .then((res) => {
          this.orgList = res;
          this.init();
        })
        .catch(this.init);
    },
    init() {
      if (this.isAdd) {
        this.edit = true;
      } else {
        this.getProcessInfo();
      }
    },
    showModal() {
      this.visible = true;
    },
    hideModal() {
      this.visible = false;
    },
    confirmSelect() {
      let list = this.$refs.orgTree.getCheckedList();
      let { dept, admin, items } = list;
      if (items.length <= 0) {
        this.$message.warning("请选择审批人");
      } else {
        let keys = {};
        dept && dept.length > 0 && (keys.dept = dept);
        admin && admin.length > 0 && (keys.admin = admin);
        this.selectItems = items;
        this.selectKeys = keys;
        this.hideModal();
      }
    },
    getProcessInfo() {
      let detail = this.$store.getters.detail;
      let id = this.$route.params.id;
      if (detail && detail.type == "process" && detail.obj.id == id) {
        this.dealInfo(detail.obj);
      } else {
        this.getDetail();
      }
    },
    getDetail() {
      let id = this.$route.params.id;
      let url = `/apply-temp/${id}?expand=group`;
      this.$axios(url).then((res) => {
        this.dealInfo(res);
      });
    },
    dealInfo(info) {
      try {
        let keys = JSON.parse(info.flow);
        let items = getCheckedItems(this.orgList, keys, true);
        this.selectKeys = keys;
        this.selectItems = items;
        if (info.form_config) {
          this.formControls = JSON.parse(info.form_config);
        }
      } catch (e) {
        console.error(e);
      }
      this.info = info;
      console.log(info)
    },
    editProcess() {
      let tab = this.tab,
        info = this.info;
      if (tab == 1) {
        this.form = clone(info);
      }
      this.edit = true;
    },
    handleFormConfSave(config, confirm) {
      if (this.isAdd) {
        this.form.form_config = config;
        if (confirm) {
          this.handleConfirm();
        } else {
          this.tab += 1;
        }
      } else {
        this.saveFormConfig(config);
      }
    },
    saveFormConfig(config) {
      let data = {
        form_config: config,
      };
      this.updateProcess(data);
    },
    prevStep() {
      if (this.showFormConf) {
        this.tab -= 1;
      } else {
        this.tab = 1;
      }
    },
    nextStep() {
      let tab = this.tab;
      if (tab == 1) {
        this.$refs.processForm.validate((valid) => {
          if (valid) {
            if (this.showFormConf) {
              this.tab += 1;
            } else {
              this.tab = 3;
            }
          }
        });
      } else if (tab == 2) {
        this.$refs.formEdit.saveForm();
      } else {
        if (this.showFormConf) {
          this.tab += 1;
        } else {
          this.tab = 3;
        }
      }
    },
    handleCancel() {
      this.edit = false;
      this.form = {};
    },
    handleConfirm() {
      let flag = this.checkForm();
      if (!flag) return;
      if (this.isAdd) {
        this.addProcess();
      } else {
        let tab = this.tab;
        if (tab == 1) {
          let form = this.form;
          let data = {
            name: getProcessTypeText(form.type),
            type: form.type,
          };
          form.type == 1 && (data.group_id = form.group_id)&& (data.is_personal = form.is_personal);
          this.updateProcess(data);
        } else if (tab == 2) {
          this.$refs.formEdit.saveForm();
        } else if (tab == 3) {
          let data = {
            flow: JSON.stringify(this.selectKeys),
          };
          this.updateProcess(data);
        }
      }
    },
    checkForm() {
      let flag = true,
        form = this.form,
        isAdd = this.isAdd,
        tab = this.tab;
      if (isAdd || tab == 1) {
          console.log(!form.is_personal )
          console.log(!form.group_id)
          console.log('----')
        if (!form.type) {
          this.$message.warning("请选择流程");
          this.tab = 1;
          flag = false;
        } else if (form.type == 1 && !form.group_id) {
          this.$message.warning("请选择群团");
          this.tab = 1;
          flag = false;
        }
      } else if (isAdd || tab == 2) {
        if (this.showFormConf) {
          if (!form.form_config) {
            if (this.$refs.formEdit.form.length <= 0) {
              this.$message.warning("请配置申请表单");
              this.tab = 2;
              flag = false;
            } else {
              this.$refs.formEdit.saveForm(true);
            }
          }
        }
      } else if (isAdd || tab == 3) {
        if (this.selectItems.length <= 0) {
          this.$message.warning("请选择审批人");
          this.tab = 3;
          flag = false;
        }
      }
      return flag;
    },
    addProcess() {
      let form = this.form,
        keys = this.selectKeys;
      let data = {
        name: getProcessTypeText(form.type),
        type: form.type,
        form_config: form.form_config,
        flow: JSON.stringify(keys),
      };
      form.type == 1 &&
        (data.group_id = form.group_id) &&
        (data.is_personal = form.is_personal);
      this.$axios({
        url: "/apply-temp",
        method: "POST",
        data,
      }).then(() => {
        this.$store.commit("changeUpdate", { type: "process" });
        this.$router.back();
      });
    },
    updateProcess(data) {
      this.$axios({
        url: "/apply-temp/" + this.info.id,
        method: "PATCH",
        data,
      }).then(() => {
        this.$store.commit("changeUpdate", { type: "process" });
        if (this.$route.query.edit) {
          this.$router.back();
        } else {
          this.handleCancel();
        }
      });
    },
    getGroupList() {
      this.$axios("/group?page=1&pageSize=100&sort=-id").then((res) => {
        let list = res.data;
        this.groupList = list.map((item) => {
          return {
            key: item.id,
            title: item.name,
          };
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.person-select,
.process-form {
  margin: 40px auto;
  width: 480px;
}
.footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: var(--border);
  justify-content: center;
}
.person-select-title {
  line-height: 32px;
}
.person-select-ctx {
  flex: 1;
  margin-left: 16px;
}
.select-item {
  display: inline-block;
  margin-right: 10px;
  padding: 0 16px;
  border: var(--border);
  border-radius: 4px;
  line-height: 32px;
}
.select-list ~ .select-btn {
  margin-top: 16px;
}
</style>
