export const processTypes = [
    {key: 1, title: '入会申请'},
    {key: 2, title: '场地预约'},
    {key: 3, title: '活动申请'},
    {key: 4, title: '名师预约'},
    {key: 5, title: '建言献策'},
    {key: 6, title: '来访预约'},
]

export const getProcessTypeText = function (type) {
    let res = processTypes.find(p => p.key == type);
    return res ? res.title : null;
}

export const getCheckedItems = function(list, keys, flag) {
    if(!list || list.length <= 0 || !keys || keys.length <= 0) return [];
    let res = [];
    let {dept, admin} = keys;
    if (dept && dept.length > 0) {
        dept.forEach(d => {
            let item = list.find(o => o.id == d);
            if (item) {
                if(flag){
                    res.push(item);
                } else {
                    res.push(`0_${item.id}`);
                }
            }
        });
    }
    if (admin && admin.length > 0) {
        dept = dept || [];
        let personList = list.reduce((acc, item) => item.admin_list ? acc.concat(item.admin_list) : acc, [])
        admin.forEach(a => {
            let item = personList.find(o => o.id == a);
            if (item && dept.indexOf(item.id) < 0) {
                if(flag){
                    res.push(item);
                } else {
                    res.push(`0_${item.dept}_${item.id}`);
                }
            }
        });
    }
    return res;
}

export const defaultFormConfig = {
    // 入会申请
    f_1: [
        {
            component: 'input',
            key: 'username',
            type: 'text',
            label: '姓名',
            placeholder: '请输入您的姓名',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入您的姓名'}
                },
            ]
        },
        {
            component: 'select',
            key: 'gender',
            mode: 'selector',
            label: '性别',
            placeholder: '请选择您的性别',
            options: ['男', '女'],
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请选择您的性别'}
                },
            ]
        },
        {
            component: 'input',
            key: 'mobile',
            label: '手机号码',
            placeholder: '请输入您的手机号码',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入您的手机号码'}
                },
                {
                    type: 2,
                    title: '手机号校验',
                    rule: {regex: '^1[3-9]\\d{9}$', message: '请输入正确的手机号码'}
                }
            ]
        },
        {
            component: 'select',
            key: 'birth',
            mode: 'date',
            label: '出省年月',
            placeholder: '请选择您的出省年月',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请选择您的出省年月'}
                },
            ]
        },
        {
            component: 'input',
            key: 'home',
            type: 'text',
            label: '籍贯',
            placeholder: '请输入您的籍贯',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入您的籍贯'}
                },
            ]
        },
        {
            component: 'select',
            key: 'political',
            mode: 'selector',
            label: '政治面貌',
            placeholder: '请选择您的政治面貌',
            options: ['中共党员', '中共预备党员', '共青团员', '民革会员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士', '群众'],
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请选择您的政治面貌'}
                },
            ]
        },
        {
            component: 'upload',
            key: 'appendix',
            label: '附件上传',
            limit: 9,
        }
    ],
    // 场地预约
    f_2: [
        {
            component: 'input',
            key: 'site',
            type: 'text',
            label: '场地名称',
            disabled: true
        },
        {
            component: 'timePeriod',
            key: 'time',
            start: null,
            end: null,
            list: [],
            label: '预约时间',
            holiday: false,
            placeholder: '请选择预约时间',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请选择预约时间'}
                },
            ]
        },
        {
            component: 'input',
            key: 'username',
            type: 'text',
            label: '申请人',
            placeholder: '请输入申请人',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入申请人'}
                },
            ]
        },
        {
            component: 'select',
            key: 'use',
            mode: 'selector',
            label: '用途',
            placeholder: '请选择用途',
            options: ['开会', '活动', '接待', '学习', '其他'],
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请选择用途'}
                },
            ]
        },
        {
            component: 'input',
            key: 'name',
            type: 'text',
            label: '活动名称',
            placeholder: '请输入活动名称',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入活动名称'}
                },
            ]
        },
        {
            component: 'input',
            key: 'mobile',
            type: 'text',
            label: '咨询电话',
            placeholder: '请输入咨询电话',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入咨询电话'}
                },
            ]
        },
        {
            component: 'upload',
            key: 'img',
            label: '活动图片',
            limit: 9,
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请上传活动图片'}
                },
            ]
        },
        {
            component: 'input',
            key: 'remark',
            type: 'textarea',
            label: '备注',
            placeholder: '请输入备注'
        },
    ],
    // 活动申请
    f_3: [
        {
            component: 'input',
            key: 'name',
            type: 'text',
            label: '活动名称',
            placeholder: '请输入活动名称',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入活动名称'}
                },
            ]
        },
        {
            component: 'select',
            key: 'date',
            mode: 'date',
            label: '活动时间',
            placeholder: '请选择活动时间',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请选择活动时间'}
                },
            ]
        },
        {
            component: 'input',
            key: 'place',
            type: 'text',
            label: '活动地点',
            placeholder: '请输入活动地点',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入活动地点'}
                },
            ]
        },
        {
            component: 'select',
            key: 'group',
            mode: 'selector',
            entity: 'group',
            label: '组织',
            placeholder: '请选择组织',
            rangeKey: 'name',
            valueKey: 'id',
            options: [],
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请选择组织'}
                },
            ]
        },
        {
            component: 'input',
            key: 'org',
            type: 'text',
            label: '组织名称',
            placeholder: '请输入组织名称',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入组织名称'}
                },
            ],
            rely: {
                key: 'group',
                valueKey: 'id',
                value: 0
            }
        },
        {
            component: 'input',
            key: 'username',
            type: 'text',
            label: '发起人',
            placeholder: '请输入发起人',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入发起人'}
                },
            ]
        },
        {
            component: 'input',
            key: 'mobile',
            type: 'text',
            label: '咨询电话',
            placeholder: '请输入咨询电话',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入咨询电话'}
                },
            ]
        },
        {
            component: 'input',
            key: 'info',
            type: 'textarea',
            label: '活动介绍',
            placeholder: '请输入活动介绍',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入活动介绍'}
                },
            ]
        },
        {
            component: 'upload',
            key: 'img',
            label: '以往图片',
            limit: 9,
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请上传以往图片'}
                },
            ]
        },
    ],
    // 名师预约
    f_4: [
        {
            component: 'input',
            key: 'teacher',
            type: 'text',
            label: '预约名师',
            disabled: true
        },
        {
            component: 'input',
            key: 'username',
            type: 'text',
            label: '姓名',
            placeholder: '请输入你的姓名',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入你的姓名'}
                },
            ]
        },
        {
            component: 'input',
            key: 'mobile',
            label: '手机号码',
            placeholder: '请输入您的手机号码',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入您的手机号码'}
                },
                {
                    type: 2,
                    title: '手机号校验',
                    rule: {regex: '^1[3-9]\\d{9}$', message: '请输入正确的手机号码'}
                }
            ]
        },
        {
            component: 'input',
            key: 'content',
            type: 'textarea',
            label: '申请内容',
            placeholder: '请输入申请内容',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入申请内容'}
                },
            ]
        },
        {
            component: 'input',
            key: 'odds',
            type: 'textarea',
            label: '优势介绍',
            placeholder: '请输入优势介绍'
        },
    ],
    // 建言献策
    f_5: [
        {
            component: 'input',
            key: 'username',
            type: 'text',
            label: '姓名',
            placeholder: '请输入你的姓名',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入你的姓名'}
                },
            ]
        },
        {
            component: 'input',
            key: 'mobile',
            label: '手机号码',
            placeholder: '请输入您的手机号码',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入您的手机号码'}
                },
                {
                    type: 2,
                    title: '手机号校验',
                    rule: {regex: '^1[3-9]\\d{9}$', message: '请输入正确的手机号码'}
                }
            ]
        },
        {
            component: 'input',
            key: 'content',
            type: 'textarea',
            label: '申请内容',
            placeholder: '请输入申请内容',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入申请内容'}
                },
            ]
        },
        {
            component: 'upload',
            key: 'img',
            label: '图片',
            limit: 9
        },
        {
            component: 'select',
            key: 'open',
            mode: 'selector',
            label: '对外公开',
            placeholder: '请选择是否对外公开',
            options: ['是', '否'],
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请选择是否对外公开'}
                },
            ]
        },
    ],
    // 来访预约
    f_6: [
        {
            component: 'input',
            key: 'username',
            type: 'text',
            label: '姓名',
            placeholder: '请输入你的姓名',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入你的姓名'}
                },
            ]
        },
        {
            component: 'select',
            key: 'gender',
            mode: 'selector',
            label: '性别',
            placeholder: '请选择您的性别',
            options: ['男', '女'],
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请选择您的性别'}
                },
            ]
        },
        {
            component: 'input',
            key: 'company',
            type: 'text',
            label: '单位',
            placeholder: '请输入你的单位',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入你的单位'}
                },
            ]
        },
        {
            component: 'input',
            key: 'number',
            type: 'number',
            label: '来访人次',
            placeholder: '请输入来访人次',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入来访人次'}
                },
            ]
        },
        {
            component: 'input',
            key: 'mobile',
            label: '手机号码',
            placeholder: '请输入您的手机号码',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入您的手机号码'}
                },
                {
                    type: 2,
                    title: '手机号校验',
                    rule: {regex: '^1[3-9]\\d{9}$', message: '请输入正确的手机号码'}
                }
            ]
        },
        {
            component: 'input',
            key: 'reason',
            type: 'textarea',
            label: '事由',
            placeholder: '请输入事由',
            rules: [
                {
                    type: 1,
                    title: '必填',
                    rule: {required: true, message: '请输入事由'}
                },
            ]
        },
    ],
}
