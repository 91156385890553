<template>
    <div class="flex-box vertical address-book">
        <a-tree
                class="flex-grow"
                v-model="keys"
                :expanded-keys="expandKeys"
                :checkable="checkable"
                :selectable="selectable"
                :tree-data="list"
                @expand="handleExpand"
                @select="handleSelect"
        />
    </div>
</template>

<script>
    import {getCheckedItems} from "../common/constant/process";

    export default {
        name: "address-book",
        props: {
            selectable: {
                type: Boolean,
                default: true
            },
            orgList: Array,
            checkable: Boolean,
            checkedKeys: Object
        },
        data() {
            return {
                keys: [],
                list: [],
                expandKeys: [],
                groupList: null
            }
        },
        watch: {
            checkedKeys(val) {
                if(val && this.keys.length <= 0) {
                    let keys = getCheckedItems(this.groupList, val);
                    this.keys = keys;
                }
            }
        },
        created() {
            if(this.orgList) {
                this.dealList(this.orgList)
            } else {
                this.getList();
            }
        },
        methods: {
            getList() {
                this.$axios('/admin/get-list').then(res => {
                    this.dealList(res)
                })
            },
            dealList(res) {
                let deptList = res.map(item => {
                    let children = item.admin_list ? item.admin_list.map(p => {
                        return {
                            key: `0_${item.id}_${p.id}`,
                            title: p.name
                        }
                    }) : [];
                    return {
                        key: `0_${item.id}`,
                        title: item.name,
                        children
                    }
                });
                if (this.checkable) {
                    this.list = deptList;
                    this.groupList = res;
                    this.expandKeys = deptList.map(item => item.key);
                    if (this.checkedKeys) {
                        let keys = getCheckedItems(res, this.checkedKeys);
                        this.keys = keys;
                    }
                } else {
                    this.list = [
                        {
                            key: "0",
                            title: "杭统云E家",
                            children: deptList
                        }
                    ]
                    this.groupList = [
                        {
                            id: "0",
                            name: "杭统云E家",
                            admin_list: res
                        }
                    ];
                    this.expandKeys = ['0'];
                }
                this.$emit("org", res);
            },
            // flag true返回item false返回key
            getCheckedItems(list, keys, flag) {
                list = list || this.groupList;
                keys = keys || this.checkedKeys;
                if(!list || keys) return [];
                let res = [];
                let {dept, admin} = this.checkedKeys;
                if (dept) {
                    dept.forEach(d => {
                        let item = list.find(o => o.id == d);
                        if (item) {
                            if(flag){
                                res.push(item);
                            } else {
                                res.push(`0_${item.id}`);
                            }
                        }
                    });
                }
                if (admin) {
                    let personList = list.reduce((acc, item) => item.admin_list ? acc.concat(item.admin_list) : acc, [])
                    admin.forEach(a => {
                        let item = personList.find(o => o.id == a);
                        if (item && dept.indexOf(item.id) < 0) {
                            if(flag){
                                res.push(item);
                            } else {
                                res.push(`0_${item.dept}_${item.id}`);
                            }
                        }
                    });
                }
                return res;
            },
            getCheckedList() {
                let keys = this.keys.map(key => key.split("_"));
                keys.sort((a, b) => {
                    return a.length - b.length;
                });
                let dept = [], admin = [], items = [], list = this.groupList;
                keys.forEach(item => {
                    if (item.length == 1) return;
                    let id = item[1]; // 部门ID
                    let d = list.find(l => l.id == id);
                    // 选择的是部门
                    if (item.length == 2) {
                        dept.push(id);
                        items.push(d);
                    } else if (item.length == 3) { //选择人员先查询其部门是否被选中
                        if (dept.indexOf(id) < 0) {
                            admin.push(item[2]);
                            let p = d.admin_list.find(a => a.id == item[2]);
                            items.push(p);
                        }
                    }
                });
                return {dept, admin, items};
            },
            handleExpand(keys) {
                this.expandKeys = keys;
            },
            handleSelect(keys) {
                let key = keys[0];
                if(key) {
                    let temp = key.split("_");
                    let item = null;
                    for (let i = 0, l = temp.length; i < l; i++) {
                        let id = temp[i];
                        let list = item ? item.admin_list : this.groupList;
                        if (list) {
                            let o = list.find(p => p.id == id);
                            if (o) {
                                if(item) {
                                    o.deptName = item.name;
                                }
                                item = o;
                            } else {
                                break;
                            }
                        } else {
                            break;
                        }
                    }
                    this.$emit("select", {ids: temp, item});
                } else {

                    this.$emit("select", {ids: [], item: null});
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .add-dept {
        justify-content: center;
        height: 36px;
        cursor: pointer;
    }
    .select-item {

    }
</style>
